import gql from "graphql-tag";

export const PAGINATE_PAPER_REQUESTS = gql`
  query filterWithPaginatePaperRequests($criteria: PaperRequestFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginatePaperRequests(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        adminApproved
        paperRequestStatus
        requestDateTime
        timestamp
        abolishReason
        abolishDateTime
        abolisher {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            passive
          }
        }
        paperRequestProcesses {
          id
          note
          nextApprover
          approved
          eventDateTime
          approver {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              team {
                name
              }
              unit {
                name
              }
              jobInfo {
                name
              }
              passive
            }
          }
        }
        paper {
          paperType {
            id
            name
          }
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              passive
              branch {
                name
              }
              unit {
                name
              }
              team {
                name
              }
              jobInfo {
                name
              }
            }
          }
          url
          publicURL
          issueDate
          expireDate
          description
          signatureStatus
          status
        }
      }
    }
  }
`;
