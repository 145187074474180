<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.paper_request_history") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template #item="row">
            <tr :active="row.selected">
              <td><employee-profile :employee="row.item.paper.employee" /></td>
              <td>{{ row.item.paper.paperType.name }}</td>
              <td>{{ row.item.paper.issueDate | formattedDate }}</td>
              <td>{{ row.item.paper.expireDate | formattedDate }}</td>
              <td>
                {{ row.item.paper.description }}
              </td>
              <td>
                <file-download-button v-if="row.item.paper.url" :url="row.item.paper.publicURL" />
              </td>
              <td>
                <request-status-label :status="row.item.paperRequestStatus" />
              </td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item: paperRequest }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <approval-history-table :item="paperRequest" processKey="paperRequestProcesses" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { PAGINATE_PAPER_REQUESTS } from "./query";

  export default {
    name: "PaperRequestHistory",
    components: { RequestStatusLabel: () => import("@/components/global/request-status-label") },
    data: (vm) => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          approver: vm.$store.state.auth.user.id,
          paperRequestStatuses: []
        },
        options: {},
        pagination: {
          page: 1,
          rowsPerPage: 10,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("global.employee"),
            value: "paperRequest.paper.employee.firstName",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.paper.type"),
            value: "paperRequest.paper.paperType.name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.paper.issue_date"),
            value: "paperRequest.paper.issueDate",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.paper.expire_date"),
            value: "paperRequest.paper.expireDate",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.description"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.file"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.imprest.status"),
            value: "imprest.requestStatus",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            align: "center",
            sortable: false,
            width: "1%"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: PAGINATE_PAPER_REQUESTS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.filterWithPaginatePaperRequests.content || [];
              this.table.totalItems = data.filterWithPaginatePaperRequests.pageInfo.totalElements || 0;
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>
